@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* ibm-plex-mono-regular - latin */
@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 400;
  src: local('SamsungOne-400');
  src: url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-400.eot);
  src: url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-400.eot?#iefix) format("embedded-opentype"), url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-400.woff2) format("woff2"), url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-400.woff) format("woff");
}
@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 700;
  src: local('SamsungOne-700');
  src: url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-700.eot);
  src: url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-700.eot?#iefix) format("embedded-opentype"), url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-700.woff2) format("woff2"), url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungOne-700.woff) format("woff");
}
@font-face {
  font-family: 'Samsung Sharp Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Samsung Sharp Sans Bold');
  src: url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungSharpSansBd.eot);
  src: url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungSharpSansBd.eot?#iefix) format("embedded-opentype"), url(https://cdn.samsung.com/etc/designs/smg/global/fonts/SamsungSharpSansBd.woff) format("woff");
}
html {
  font-family: "SamsungOne", 'Google Sans', arial, Helvetica, sans-serif;
}
}